var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboards',
        icon: 'ni ni-shop text-primary',
        path: '/dashboard',
      }},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Employee',
        icon: 'ni ni-single-02 text-primary',
      }},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Add Employee', path: '/add-employee' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Employee List', path: '/employee-list' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Employee Type', path: '/employee-type' }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Client',
        icon: 'ni ni-single-02 text-primary',
      }},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Client List', path: '/client-list' }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Service',
        icon: 'ni ni-single-02 text-primary',
      }},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Add Service', path: '/addservice' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Service List', path: '/servicelist' }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Blog',
        icon: 'ni ni-single-02 text-primary',
      }},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Add Blog', path: '/addBlog' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Blog List', path: '/blogList' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Blog Category', path: '/blog-category' }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Website',
        icon: 'ni ni-single-02 text-primary',
      }},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Image Upload', path: '/website-image-upload' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Testimonials', path: '/testimonials' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Banner', path: '/website-banner' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Menu', path: '/website-menu' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Video Link', path: '/website-video' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Partners Logo', path: '/website-partners-logo' }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Registration',
        icon: 'ni ni-single-02 text-primary',
      }},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Registration List', path: '/registration-list' }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Settings',
        icon: 'ni ni-single-02 text-primary',
      }},model:{value:(_vm.showCollapse),callback:function ($$v) {_vm.showCollapse=$$v},expression:"showCollapse"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Location', path: '/location' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Company Info', path: '/company-info' }}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }