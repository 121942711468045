<template>
  <side-bar>
    <template slot="links">
      <sidebar-item
        :link="{
          name: 'Dashboards',
          icon: 'ni ni-shop text-primary',
          path: '/dashboard',
        }"
        v-model="showCollapse"
      >
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Employee',
          icon: 'ni ni-single-02 text-primary',
        }"
        v-model="showCollapse"
      >
        <sidebar-item
          :link="{ name: 'Add Employee', path: '/add-employee' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Employee List', path: '/employee-list' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Employee Type', path: '/employee-type' }"
        ></sidebar-item>
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Client',
          icon: 'ni ni-single-02 text-primary',
        }"
        v-model="showCollapse"
      >
        <sidebar-item
          :link="{ name: 'Client List', path: '/client-list' }"
        ></sidebar-item>
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Service',
          icon: 'ni ni-single-02 text-primary',
        }"
        v-model="showCollapse"
      >
        <sidebar-item
          :link="{ name: 'Add Service', path: '/addservice' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Service List', path: '/servicelist' }"
        ></sidebar-item>
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Blog',
          icon: 'ni ni-single-02 text-primary',
        }"
        v-model="showCollapse"
      >
        <sidebar-item
          :link="{ name: 'Add Blog', path: '/addBlog' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Blog List', path: '/blogList' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Blog Category', path: '/blog-category' }"
        ></sidebar-item>
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Website',
          icon: 'ni ni-single-02 text-primary',
        }"
        v-model="showCollapse"
      >
        <sidebar-item
          :link="{ name: 'Image Upload', path: '/website-image-upload' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Testimonials', path: '/testimonials' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Banner', path: '/website-banner' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Menu', path: '/website-menu' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Video Link', path: '/website-video' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Partners Logo', path: '/website-partners-logo' }"
        ></sidebar-item>
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Registration',
          icon: 'ni ni-single-02 text-primary',
        }"
        v-model="showCollapse"
      >
        <sidebar-item
          :link="{ name: 'Registration List', path: '/registration-list' }"
        ></sidebar-item>
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Settings',
          icon: 'ni ni-single-02 text-primary',
        }"
        v-model="showCollapse"
      >
        <sidebar-item
          :link="{ name: 'Location', path: '/location' }"
        ></sidebar-item>
        <sidebar-item
          :link="{ name: 'Company Info', path: '/company-info' }"
        ></sidebar-item>
      </sidebar-item>
      <!--Divider-->
      <!-- <hr class="my-1"> -->
      <!--Heading-->
      <!-- <h6 class="navbar-heading text-muted">Documentation</h6> -->
    </template>
  </side-bar>
</template>
<script>
export default {
  data() {
    return {
      showCollapse: false
    };
  },
  watch: {
    $route() {
      this.showCollapse = false;
    }
  }
};
</script>
